
  import { Watch,Mixins,Component, Vue, ProvideReactive } from "vue-property-decorator";
  import PrescriptionProductListCom from "./components/productList.vue";
  
  import { getPresCategoryList } from "@/api/prescriptionDb"
  
  import { cloneDeep } from "lodash";
  
  @Component({
      inject: [],
      components: {
          PrescriptionProductListCom
      }
  })
  export default class PrescriptionMerchantProductList extends Vue {
      categoryList = [];

      @ProvideReactive()
      merchantId = this.$route.query.merchantId || 0;
  
      @ProvideReactive()
      categoryInfo = {} as any;
  
      mounted() {
          this.getData();
      }
      getData() {
        getPresCategoryList({ merchantId: this.merchantId }).then((res) => {
            this.categoryList = res.data;
            this.categoryInfo = res.data[0];
        })
      }
  
      getProductList(category) {
          this.categoryInfo = cloneDeep(category);
      }
  
  }
  